body.dark
{
  background-color: #282c34;
}
body.dark .ui.segment
{
  background-color: transparent !important;
  color: #aaa;
}

body.dark .ui.header
{
  color: #cca !important;
}
body.dark .ui.label
{
  background-color: transparent;
  color: #999;
}

body.dark #root
{
  border-color: #808080;
}

body.dark #main_menu
{
  border-bottom-color: #888;
  background-color: #1b1c1d;
}
body.dark #main_menu .item
{
  color: #ccc;
  border-color: rgba(255, 255, 255, 0.1);
}
body.dark #main_menu .item:hover
{
  background-color: rgba(255, 255, 255, 0.08);
}
body.dark #main_menu .item.active
{
  background-color: rgba(255, 255, 255, 0.2);
}
body.dark #main_menu #prod-status p{
  color: white;
}
body.dark #main_menu #prod-status p span{
  color: rgb(253, 243, 48);
  margin-left: 5px;
}
body, #root, main
{
  margin: 0;
  height: 100%;
}
.ui.segments
{
  height: 100%;
  margin: 0 !important;
  border: none !important;
}
.ui.segment
{
  margin: 0;
  padding: 0;
  border-radius: 0;
  border: none;
  box-shadow: none;
}

#root
{
  border-width: 2px;
  border-style: solid;
  min-width: 1200px;
}

#main_menu
{
  height: 28px;
  border-radius: 0;
  min-height: 0;
  margin: 0;
  border-bottom: 2px solid #888;
}
#main_menu .item
{
  padding: 0.5em 1.0em;
  border-width: 1px;
  border-style: solid;
  font-weight: bold;
  color: gray !important;
}
#main_menu .active
{
  background-color: #1a518d !important;
  color: white !important;
}

#main_menu .item:first-child
{
  border-radius: 0;
  /* width: 85px; */
}
#main_menu .header
{
  color: #fff;
  margin-left: auto;
  margin-right: auto;
}

#main_grid
{
  height: calc(100% - 28px);
}
iframe {
  height: 100%;
  width: 100%;
  border: none;
}
#main_grid.background
{
  height: calc(100% - 28px);
  /*background-image: url('../images/background.jpg');*/
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-position: center;
  background-size: cover;
}

#main_footer
{
  padding: 0.3em 0.6em;
}
#main_footer.default
{
  background-color: #1b1c1d !important;
}
#main_footer.notice
{
  background-color: #005000 !important;
  color: #EEE;
}
#main_footer.warning
{
  background-color: yellow !important;
}
#main_footer.error
{
  background-color: #522 !important;
  color: #DDD;
}
.release-notes h4.ui.header,
.release-notes b,
.release-notes p{
  color: #6FCDEC !important;
  margin: 6px 0;
  font-size: medium;
}

.release-notes {
  position: fixed;
  top: 40px;
  width: 600px;
}
.status-bar,
.status-labels {
  display: inline;
}
.status-labels {
  margin-left: 5px;
}

.status-labels span{
  font-size: 13px;
  font-weight: bold;
  color: white;
}

.status.ui.label {
  padding: 2px 5px;
}

.status.ui.label .detail{
  margin-left:4px;
  padding: 2px 5px;
  margin-top: -2px;
  color: #e5e5e5;
  opacity: 1;
}

.status.ui.label .detail.Waiting{
  background-color: darkred;
}
.status.ui.label .detail.Complete{
  background-color: #287c31;
}
.status.ui.label .detail.Processing{
  background-color: #9F7D3A;
}